import React from 'react'
import { FaFacebook, FaInstagram, FaLinkedin, FaMailBulk, FaPhone, FaSearchLocation, FaYoutube, FaWhatsapp } from 'react-icons/fa'

const Footer = () => {
    return (
        <div className='footer'>
            <div className='footer-container'>
                <div className='left'>
                    <div className='location'>
                        <FaSearchLocation size={20} style={{ color: '#ffffff', marginRight: '2rem' }} />
                        <div>
                            <h4>#521 Chickweed Street.</h4>
                            <h4>Apam, Gh</h4>
                        </div>
                    </div>
                    <div className='phone'>
                        <h4><FaPhone size={20} style={{ color: '#ffffff', marginRight: '2rem' }} /> 
                        <a href='tel:+233247792110' target="blank"><span>+233 24 7792110</span></a>
                        </h4>
                        <h4>
                            <FaWhatsapp size={20} style={{color:'#ffffff', marginRight:'2rem'}}/><a href='https://wa.me/+233247792110' target="blank">send a whatsapp message</a>
                            </h4>
                    </div>
                    <div className='email'>
                        <h4><FaMailBulk size={20} style={{ color: '#ffffff', marginRight: '2rem' }} />
                        <a href='mailto:info.superj@gmail.com' target="blank"> <span>info.dunamistravels@gmail.com</span> </a> </h4>
                    </div>

                </div>
                <div className='right'>
                    <h4>About the company</h4>
                    <p>Dunamis Travels is a project inspired by the love to help/assist
                        persons who also have interest in traveling overseas when i got the
                        chance to travel by plane for the first time.<br />
                        Excellence is our hallmark.
                    </p>
                    <div className='social'>
                        <a href='https://www.facebook.com/DunamisTravels1'><FaFacebook size={30} style={{ color: '#ffffff', marginRight: '1rem' }} /></a>
                        <a href='https://www.instagram.com/superjcybs'><FaInstagram size={30} style={{ color: '#ff2311', marginRight: '1rem' }} /></a>
                        <a href='https://www.linkedin.com/company/super-j-cy-business-and-services'><FaLinkedin size={30} style={{ color: '#ffffff', marginRight: '1rem' }} /></a>
                        <a href='https://www.youtube.com/channel/UCnYL6e5pYht_mWKlEE7NTLw'><FaYoutube size={30} style={{ color: '#ff0000', marginRight: '1rem' }} /></a>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Footer
