import React from 'react'

import spaceVideo from '../assets/space.mp4'

const Video = () => {
    return (
        <div className='hero'>
            <video autoPlay loop muted id='video'>
                <source src={spaceVideo} type='video/mp4' />
            </video>
            <div className='content'>
                <h1>Dunamis Travel.</h1>
                <p>Its Your Time to Travel.</p>
                <div>
                    <a href='https://superjcybs.com/dunamistravels' className='btn'>Find Out More</a>
                </div>
            </div>
        </div>
    )
}

export default Video
